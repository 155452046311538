import React, { useState, useRef } from 'react';
import { jsPDF } from "jspdf";

function FormToPDF() {
    const [formData, setFormData] = useState({
        studentName: '',
        studentNumber: '',
        teacherName: '',
        examCode: '',
        motivation: '',
        date: '',
        email: '',
    });

    const [formErrors, setFormErrors] = useState({
        studentName: '',
        studentNumber: '',
        teacherName: '',
        examCode: '',
        motivation: '',
        date: '',
        email: '',
    });

    const [isFormFilled, setIsFormFilled] = useState(false);
    const formRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
        // Clear errors on change
        if (value.trim() !== '') {
            setFormErrors(prevErrors => ({
                ...prevErrors,
                [name]: '',
            }));
        }
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (value.trim() === '') {
            setFormErrors(prevErrors => ({
                ...prevErrors,
                [name]: 'Dit veld is verplicht en mag niet leeg zijn.',
            }));
        } else {
            e.target.readOnly = true;
            checkIfFormFilled();
        }
    };

    const checkIfFormFilled = () => {
        const allFilled = Object.values(formData).every(value => value.trim() !== '');
        setIsFormFilled(allFilled);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormFilled) {
            const pdf = new jsPDF();
            pdf.setFontSize(12);
            pdf.text('Dienst Onderwijs & Studentenservice', 20, 30);

            pdf.setFontSize(16);
            pdf.text('MOTIVATIE AANVRAAG DERDE EXAMENGELEGENHEID', 20, 50);

            pdf.setFontSize(12);
            const startY = 60;
            const baseLineGap = 5; // Basisafstand tussen lijnen
            const entryGap = 5; // Extra afstand tussen entries
            const labelX = 20; // X positie voor labels
            let valueX = 100; // Start X positie voor waarden
            let currentY = startY;
            const pageWidth = pdf.internal.pageSize.getWidth();
            const marginRight = 20; // Rechtermarge van de pagina

            // Bereken de maximale breedte van de labels
            let maxLabelWidth = 0;
            Object.keys(formData).forEach((key) => {
                const labelWidth = pdf.getStringUnitWidth(fieldLabels[key]) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
                if (labelWidth > maxLabelWidth) {
                    maxLabelWidth = labelWidth;
                }
            });

            // Pas valueX aan op basis van maxLabelWidth
            valueX = Math.max(valueX, labelX + maxLabelWidth + 10); // Voeg wat ruimte toe tussen label en waarde

            const valueWidth = pageWidth - valueX - marginRight; // Beschikbare breedte voor waarden

            Object.keys(formData).forEach((key) => {
                const label = `${fieldLabels[key]}:`;
                const value = `${formData[key]}`;
                const splitValue = pdf.splitTextToSize(value, valueWidth); // Gebruik de berekende waardebreedte

                pdf.text(label, labelX, currentY);
                pdf.text(splitValue, valueX, currentY);

                // Verhoog Y positie voor de volgende entry
                currentY += (splitValue.length * baseLineGap) + entryGap;
            });

            pdf.setFontSize(10);
            pdf.text("www.summacollege.nl", 20, currentY + 10); // Aanpassing voor positie van de footer
            pdf.save(`${formData.studentNumber}.pdf`);
        } else {
            alert('Niet alle velden zijn ingevuld.');
        }
    };



    const inputStyle = {
        borderColor: '#000000',
        color: '#24126e',
    };

    const errorStyle = {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: '5px',
    };

    const fieldLabels = {
        studentName: 'Naam student',
        studentNumber: 'Studentnummer (zonder PS)',
        teacherName: 'Naam vakdocent',
        examCode: 'Examencode',
        motivation: 'Motivatie',
        date: 'Datum',
        email: 'E-mailadres'
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
        }}>
            <form ref={formRef} onSubmit={handleSubmit} style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                maxWidth: '500px',
                width: '90%', // Responsive width
                margin: '0 auto' // Centering the form
            }}>
                <img src="https://www.summacollege.nl/assets/img/logo.svg" style={{
                    maxWidth: '200px',
                    width: '50%'
                }} />
                <p> MOTIVATIE AANVRAAG DERDE EXAMENGELEGENHEID</p>
                Let op: Na het verlaten van een veld kan de inhoud niet meer gewijzigd worden.
                {Object.keys(formData).map(key => (
                    <div key={key} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px'
                    }}>
                        <label>{fieldLabels[key]}:</label>
                        {key === 'motivation' ? (
                            <textarea
                                name={key}
                                style={inputStyle}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        ) : (
                            <input
                                type={key === 'email' ? 'email' : key === 'date' ? 'date' : 'text'}
                                name={key}
                                style={inputStyle}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        )}
                        {formErrors[key] && <div style={errorStyle}>{formErrors[key]}</div>}
                    </div>
                ))}
                <button type="submit" style={{
                    maxWidth: '150px',
                    alignSelf: 'center',
                    background: '#24126e',
                    padding: '10px',
                    color: 'white',
                    border: 'none'
                }}>
                    Opslaan als PDF
                </button>
            </form>
        </div>
    );

}

export default FormToPDF;
